<template>
  <div class="delPaper">
    <div class="table">
      <div class="head">
        <span>我删除的试卷</span>
        <div class="subject">科目:
          <el-dropdown style="margin-left:20rem;margin-top:-5rem;color:#2196F3">
            <span class="el-dropdown-link">
              {{subject_name}}<i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item v-for="item in subject_list"
                                @click.native.prevent="checkItem(item)"
                                :key="item.subject_id + ''">{{item.subject_name}}</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </div>

      <div class="list_head">
        <el-row>
          <el-col :span="6">
            <div class="paper_name score">
            </div>
          </el-col>
          <el-col :span="3">
            <div class="score">
              组卷类型
            </div>
          </el-col>
          <el-col :span="3">
            <div class="score">
              评分类型
            </div>
          </el-col>
          <el-col :span="5">
            <div class="score">
              试卷时间
            </div>
          </el-col>
          <el-col :span="5">
            <div class="score">
              删除时间
            </div>
          </el-col>
          <el-col :span="2">
            操作
          </el-col>
        </el-row>
      </div>
      <div class="list">
        <el-row v-show="!tableData||tableData.length==0"> 暂无数据</el-row>
        <el-row v-show="tableData&&tableData.length>0"
                v-for="(item,index) in tableData"
                :key="index">
          <el-col :span="6">
            <div class="paper_name">
              <span>{{item.template_name||''}}</span><br>
              <span>试卷ID: {{item.user_paper_id}}</span>
            </div>
          </el-col>
          <el-col :span="3">
            <div class="score">
              {{moduleList[item.module_id]||''}}
            </div>
          </el-col>
          <el-col :span="3">
            <div class="score">
              自评试卷
            </div>
          </el-col>
          <el-col :span="5">
            <div class="score">
              {{item.paper_time||''}}
            </div>
          </el-col>
          <el-col :span="5">
            <div class="score">
              {{item.del_time||''}}
            </div>
          </el-col>
          <el-col :span="2">
            <div class="btn_warp">
              <el-popconfirm confirm-button-text='确定'
                             cancel-button-text='取消'
                             icon="el-icon-info"
                             icon-color="orange"
                             title="确定恢复该试卷？"
                             @confirm="recoverPaper(item)">
                <div class="btn"
                     slot="reference">
                  恢复
                </div>
              </el-popconfirm>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>
    <div class="pagination">
      <pagination ref="pagination"
                  :get-data="getList"
                  :now-page.sync="page"
                  :now-size.sync="limit"
                  :total="total" />
    </div>
  </div>
</template>

<script>
import { myDelPaper, recoverDelPaper } from '@/api/my_paper.js'
import { mapMutations, mapState } from 'vuex'
import { getStudentSubject } from '@/api/subject.js'
export default {
  data () {
    return {
      tableData: [],
      total: 0,
      subjectId: null,
      subject_name: "",
      page: 1,
      limit: 10,
      moduleList: {
        1: '自组组卷',
        2: '考点组卷',
        3: '整卷组卷',
        4: '知识点组卷',
        5: '章节组卷'
      }
    }
  },
  mounted () {
    this.getSubjectList()
  },
  computed: {
    subjects () {
      return this.$store.state.subjectList
    },
    // ...mapState(['subjectList']),
    subject_list () {
      return this.subjects.filter(item => item.subject_id != 13)
    }
  },
  methods: {
    ...mapMutations(['SET_SUBJECT']),
    async getSubjectList () {
      if (!this.subjects.length) {
        const { data } = await getStudentSubject()
        this.SET_SUBJECT(data.list);
      }
      this.subjectId = this.subject_list[0].subject_id
      this.subject_name = this.subject_list[0].subject_name
      this.getList()
    },
    checkItem (item) {
      this.subject_name = item.subject_name
      this.subjectId = item.subject_id
      this.getList()
    },
    async getList (page, limit) {
      if (!this.subjectId) {
        return
      }
      if (page || limit) {
        this.page = page
        this.limit = limit
      }
      let params = {
        subject_id: this.subjectId,
        limit: this.limit,
        page: this.page
      }
      const { data } = await myDelPaper(params)
      this.tableData = data.volist;
      this.total = data.count
    },
    async recoverPaper (row) {
      const { data } = await recoverDelPaper({
        user_paper_id: row.user_paper_id
      })
      this.$message.success("恢复成功，可在备考进度中查看")
      this.getList()
    }
  }
}
</script>

<style lang="scss" scoped>
.delPaper {
  width: 94%;
  margin-top: 30rem;
  margin-left: 40rem;
  .head {
    font-size: 24rem;
    font-weight: 800;
    color: #333;
    display: flex;
    justify-content: space-between;
    margin-bottom: 25rem;
  }
  .btn {
    font-size: 16rem;
    border: 1px solid #1b93e4;
    color: #1b93e4;
    display: inline-block;
    padding: 5rem 15rem;
    cursor: pointer;
    background: #fff;
    border-radius: 5px;
    &:hover {
      background: #effaff;
      color: #149af3;
    }
  }
}
.el-dropdown-link {
  color: #333;
  font-size: 24rem;
  cursor: pointer;
}
.list_head {
  width: 100%;
  background: #f9f9f9;
  text-align: center;
  box-shadow: inset 2px -1px 13px #eff2ff, 2px -1px 4px #e0e1e2;
  .el-row {
    font-size: 20rem;
    padding: 20rem;
    color: #333;
    border-bottom: 1rem solid #f1f1f1;
  }
}
.score {
  width: 100%;
  display: inline-block;
}
.paper_name {
  span {
    &:nth-child(1) {
      font-weight: 700;
      line-height: 40rem;
    }
    &:nth-child(2) {
      font-weight: 500;
    }
  }
}
.list {
  width: 100%;
  text-align: center;
  color: #333;
  max-height: calc(100vh - 60rem - 40rem);
  -ms-overflow-style: none;
  background: white;
  overflow-y: auto;
  overflow: -moz-scrollbars-none;
  box-shadow: 2px 1px 4px #e0e1e2;
  &::-webkit-scrollbar {
    width: 0 !important;
  }
  .el-row {
    font-size: 20rem;
    padding: 20rem;
    border-bottom: 1rem solid #f1f1f1;
    display: flex;
    align-items: center;
    &:hover {
      background: rgba(33, 150, 243, 0.1);
    }
  }
}
.pagination {
  margin-top: 10rem;
}
</style>